<template>
  <div class="add">
    <block title="基本信息" :editable="false">
      <el-form>
        <el-form-item label="姓名">
          <el-input  size="medium" v-model="form.staffName" :disabled="!!this.id"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input  size="medium"
            v-model="form.mobile"
            @input="mobileChange"
            :disabled="!!this.id"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">{{
          form.sysDeptName && form.sysDeptName.deptName
        }}</el-form-item>
        <el-form-item label="岗位">
          {{
            form.sysPositionNameList &&
            form.sysPositionNameList.map((e) => e.positionName).join(",")
          }}
        </el-form-item>
        <el-form-item label="入职日期">{{
          form.entryTime || "无"
        }}</el-form-item>
        <el-form-item label="劳动合同主体">{{
          form.contractsPartA || "无"
        }}</el-form-item>
        <el-form-item label="是否为经理人">{{
          form.isManager ? "是" : "否"
        }}</el-form-item>
        <el-form-item label="是否为党员">{{
          form.joinPartyTime ? "是" : "否"
        }}</el-form-item>
      </el-form>
    </block>

    <block title="流转单审批人" :editable="false">
      <flow :flows="form.bpmActivityModel" @updateFlows="updateFlows"></flow>
    </block>

    <div class="footer">
      <el-button @click="back" size="medium">返回</el-button>
      <el-button @click="reset" size="medium" v-if="!this.id">重置</el-button>
      <el-button @click="submit" type="primary" v-throttle size="medium"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import block from "@/components/block.vue";
import SelectApproval from "@/components/selectApproval.vue";
import Flow from "@/components/flow";
export default {
  components: { Flow, block, SelectApproval },
  name: "add",
  data() {
    return {
      name: "", // 姓名
      form: {
        staffName: "",
        sysDeptName: {
          deptName: "",
        },
      },
      region: 601,
      draftId: "",
    };
  },
  props: ["id", "from"],
  watch: {
    region(val) {
      this.updateBpmModel(val);
    },
  },
  methods: {
    updateFlows(data) {
      this.form.bpmActivityModel = data;
      this.saveDraft();
    },
    back() {
      if (this.from != "sponsor") {
        this.$router.push({
          path: "/businessModule/personnel/entryFlow",
        });
      }
      this.$emit("changeType", {
        type: "list",
      });
    },
    mobileChange(value) {
      if (value.length == 11) {
        this.$api.personnel
          .staffRosterList({
            mobile: this.form.mobile,
            pageNum: 1,
            pageSize: 10,
            staffName: this.form.staffName,
          })
          .then((res) => {
            if (res.code == 200 && res.data.list.length > 0) {
              let { mobile, bpmActivityModel } = this.form;
              this.form = Object.assign(res.data.list[0], {
                mobile,
                bpmActivityModel,
              });
              this.form.staffRosterId = this.form.id;
              this.saveDraft();
            }
          });
      }
    },
    updateBpmModel(val) {
      this.$api.personnel.bpmGetModelValue(val).then((res) => {
        res.data &&
          (this.form.bpmActivityModel = res.data.nodeList.map((ele) => {
            ele.userList || (ele.userList = []);
            this.$set(ele, "visible", false);
            this.$forceUpdate();
            return ele;
          }));
      });
    },
    validate() {
      if (
        this.form.bpmActivityModel
          .filter((e) => e.nodeType == 1)
          .every((e) => e.userList.length == 0)
      ) {
        this.$message.warning("请至少选择一个审批人");
        return false;
      }
      for (const ele of this.form.bpmActivityModel) {
        if (ele.isOwnSetting === 0 && ele.userList && ele.userList.length) {
          if (ele.userList.some((e) => e.id == -1)) {
            this.$message.warning("请选择人员，或删除空的节点");
            return false;
          }
        }
      }
      return true;
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      console.log(this.form);
      if (this.id) {
        // 驳回修改再次提交
        this.$api.personnel
          .approvalPersonOperationUpdate({
            id: this.id,
            staffRosterId: this.form.staffRosterId,
            activityNodeDetails: this.form.bpmActivityModel,
          })
          .then((res) => {
            this.$message.success(res.msg);
            setTimeout(() => {
              this.back();
            }, 1000);
          });
      } else {
        this.$api.personnel
          .approvalPersonOperationSubmit({
            id: this.draftId,
            staffRosterId: this.form.staffRosterId,
            activityNodeDetails: this.form.bpmActivityModel,
          })
          .then((res) => {
            this.$message.success(res.msg);
            setTimeout(() => {
              this.back();
            }, 1000);
          });
      }
    },
    reset() {
      this.$confirm("是否确认重置？")
        .then((res) => {
          console.log(res);
          this.$api.personnel
            .reset({
              typeId: 6,
            })
            .then((res) => {
              this.form = {};
              this.updateBpmModel(this.region);
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserDraft() {
      this.$api.personnel
        .approvalPersonOperationGetUserDraft()
        .then(({ data }) => {
          console.log(data);
          if (data.id) {
            this.draftId = data.id;
            this.form = data;
          }
          this.updateBpmModel(this.region);
          // if (data.activityModel) {
          //   this.form.bpmActivityModel = data.activityModel.activitiNodeDetails;
          // } else {
          //   this.updateBpmModel(this.region);
          // }
        });
    },
    saveDraft() {
      if (this.draftId) {
        this.draftUpdate();
      } else {
        this.draftInsert();
      }
    },
    draftInsert() {
      this.$api.personnel
        .approvalPersonOperationDraftInsert({
          staffRosterId: this.form.id,
          activityNodeDetails: this.form.bpmActivityModel,
        })
        .then(({ data }) => {
          this.draftId = data.id;
        });
    },
    draftUpdate() {
      this.$api.personnel
        .approvalPersonOperationDraftUpdate({
          id: this.draftId,
          staffRosterId: this.form.id,
          activityNodeDetails: this.form.bpmActivityModel,
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
  created() {
    let jobNumber = this.$route.query.jobNumber;
    if (jobNumber) {
      this.$api.personnel.staffRosterGet({ jobNumber }).then((res) => {
        this.form = res.data;
        this.updateBpmModel(this.region);
      });
    } else if (this.id) {
      this.$api.personnel.approvalPersonOperationGet(this.id).then((res) => {
        this.form = res.data;
        this.form.bpmActivityModel = res.data.activityModel.activitiNodeDetails;
      });
    } else {
      this.getUserDraft();
    }
  },
};
</script>

<style lang="scss" scoped>
.add {
  padding: 20px;
  background-color: white;
  .block {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        flex-shrink: 0;
        display: flex;
      }
    }
  }
  .select-person {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .footer {
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
</style>