<template>
  <div class="detail">
    <block>
      <el-tabs type="card" class="diagram-content_tab" v-model="activeTab">
        <el-tab-pane
          label="当前流程"
          name="current"
          class="diagram-content_current"
        >
          <detail-flow
            :flows="[form.activityModel.activitiNodeDetails]"
            :data-status="form.status"
          ></detail-flow>
          <block title="基本信息" :editable="false" class="basic-info">
            <el-form>
              <el-form-item label="姓名">
                {{ form.staffName }}
              </el-form-item>
              <el-form-item label="手机号">
                {{ form.mobile }}
              </el-form-item>
              <el-form-item label="部门">
                {{ form.sysDeptName && form.sysDeptName.deptName }}
              </el-form-item>
              <el-form-item label="岗位">
                {{
                  form.sysPositionNameList &&
                  form.sysPositionNameList.map((e) => e.positionName).join(",")
                }}
              </el-form-item>
              <el-form-item label="入职日期">
                {{ form.entryTime || "无" }}
              </el-form-item>
              <el-form-item label="劳动合同主体">
                {{ form.contractsPartA || "无" }}
              </el-form-item>
              <el-form-item label="是否为经理人">
                {{ form.isManager ? "是" : "否" }}
              </el-form-item>
              <el-form-item label="是否为党员">
                {{ form.joinPartyTime ? "是" : "否" }}
              </el-form-item>
            </el-form>
          </block>
        </el-tab-pane>
        <el-tab-pane
          label="历史记录"
          v-if="showHistory&&!(from === 'copyToMe' || from === 'approval')"
          name="history"
          class="diagram-content_history"
        >
          <template v-if="historyRecord && historyRecord.length">
            <div
              class="record-item"
              v-for="(record, index) in historyRecord"
              :key="index"
              style="
                border: 1px solid lightgray;
                border-radius: 5px;
              "
            >
              <detail-flow
                :flows="[record.activityModel.activitiNodeDetails]"
                :data-status="record.status"
              ></detail-flow>
              <block title="基本信息" :editable="false" class="basic-info">
                <el-form>
                  <el-form-item label="姓名">
                    {{ record.staffName }}
                  </el-form-item>
                  <el-form-item label="手机号">
                    {{ record.mobile }}
                  </el-form-item>
                  <el-form-item label="部门">
                    {{ record.sysDeptName && record.sysDeptName.deptName }}
                  </el-form-item>
                  <el-form-item label="岗位">
                    {{
                      record.sysPositionNameList &&
                      record.sysPositionNameList
                        .map((e) => e.positionName)
                        .join(",")
                    }}
                  </el-form-item>
                  <el-form-item label="入职日期">
                    {{ record.entryTime || "无" }}
                  </el-form-item>
                  <el-form-item label="劳动合同主体">
                    {{ record.contractsPartA || "无" }}
                  </el-form-item>
                  <el-form-item label="是否为经理人">
                    {{ record.isManager ? "是" : "否" }}
                  </el-form-item>
                  <el-form-item label="是否为党员">
                    {{ record.joinPartyTime ? "是" : "否" }}
                  </el-form-item>
                </el-form>
              </block>
            </div>
          </template>
          <template v-else>
            <div class="no-record-data">暂无数据</div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </block>

    <div style="height: 76px"></div>

    <!-- <block title="签署审批" :editable="false">
      <el-form v-if="form.activityModel.lastDetail">
        <el-form-item label="简要意见">{{
          form.activityModel.lastDetail.dealRemark ||
          form.activityModel.lastDetail.dealReson ||
          "无"
        }}</el-form-item>
        <el-form-item label="审核人">{{
          form.activityModel.lastDetail.userList.length
            ? form.activityModel.lastDetail.userList[0].name
            : "无"
        }}</el-form-item>
        <el-form-item label="审核日期">{{
          form.activityModel.lastDetail.dealTime
        }}</el-form-item>
      </el-form>
    </block> -->
    <div class="footer">
      <el-button @click="back" size="medium">返回</el-button>
    </div>

    <template>
      <el-dialog :visible.sync="visible" title="提示">
        <el-form>
          <el-form-item label="驳回理由" required v-if="type == 'reject'">
            <el-input v-model="rejectReason" rows="5" type="textarea">
            </el-input>
          </el-form-item>
          <el-form-item label="备注" required v-if="type == 'agree'">
            <el-input v-model="rejectReason" rows="5" type="textarea">
            </el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button @click="visible = false" size="medium">取消</el-button>
          <el-button type="primary" @click="submit" v-throttle size="medium"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import Block from "@/components/block.vue";
import DetailFlow from "@/components/detail-flow";
export default {
  name: "detail",
  components: { Block, DetailFlow },
  data() {
    return {
      activeTab: "current",
      visible: false,
      type: "",
      list: [
        {
          name: "",
          visible: false,
          id: Math.random(),
          remark: "",
          pass: false,
        },
      ],
      form: {
        activityModel: {
          activitiNodeDetails: [],
        },
      },
      historyRecord: [],
    };
  },
  props: {
    id:[Number, String],
    from:String,
    showHistory: {
      type:Boolean,
      default(){
        return true
      }
    }
  }
  ,
  created() {
    // this.$api.personnel
    //   .approvalPersonOperationGetHistory({ id: this.id })
    //   .then(({ data }) => {
    //     this.historyRecord = data?data.list:[];
    //   });
    this.$api.personnel.approvalPersonOperationGet(this.id).then((res) => {
      if((res.data || {}).hasHistory){
        this.$api.personnel
        .approvalPersonOperationGetHistory({ id: this.id })
        .then(({ data }) => {
          this.historyRecord = data?data.list:[];
        });
      }
      res.data.activityModel.activitiNodeDetails.forEach((e) => {
        if (e.dealType === "0") {
          e.color = "#5c6be8";
        } else if (e.dealType === "1") {
          e.color = "#FF6490";
        }
      });
      this.form = res.data;
    });
  },
  methods: {
    openDialog(type) {
      this.type = type;
      this.visible = true;
    },
    submit() {
      this.visible = false;
      this.$emit("changeType", {
        type: "list",
      });
    },
    back() {
      this.$emit("changeType", {
        type: "list",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  .select-person {
    display: flex;
    flex-direction: column;
    .select-person-item {
      width: 100%;
      float: left;
      min-height: 40px;
      margin: 10px 0;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    background-color: white;
    left: 260px;
    display: flex;
    width: calc(100% - 280px);
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    z-index: 10;
  }
}
.block {
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
}
</style>